<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" fullscreen>
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <v-btn small color="blue-grey" @click="addRow" class="mt-4 text-white">
              <v-icon big color="green"> add </v-icon>
              <span> Agregar Movimiento</span>
            </v-btn>

            <v-data-table :headers="headers" :items="remitos" class="mt-4 fixed-width-table">
              <template v-slot:item="props">
                <tr>
                  <td :style="{ minWidth: '150px' }">
                    <v-text-field data-vv-as="nombre" :name="'NameSubClient_' + props.index" v-model="props.item.NameSubClient" v-validate="'required'" :label="$t('Nombre')"></v-text-field>
                    <span class="red--text">{{ errors.first("NameSubClient_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '250px' }">
                    <v-text-field data-vv-as="direccion" label="Direccion*" :name="'AddressSubClient_' + props.index" v-model="props.item.AddressSubClient"></v-text-field>
                    <span class="red--text">{{ errors.first("AddressSubClient_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '150px' }">
                    <v-combobox
                      v-model="props.item.CountrySubClient"
                      data-vv-as="pais"
                      :name="'CountrySubClient_' + props.index"
                      label="Pais*"
                      item-text="name"
                      item-value="id"
                      :items="countries"
                      autocomplete="off"
                      v-validate="'required'"
                      @change="loadProvincies(props.item.CountrySubClient.id)"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("CountrySubClient_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '150px' }">
                    <v-combobox
                      v-model="props.item.ProvinceSubClient"
                      data-vv-as="provincia"
                      :name="'ProvinceSubClient_' + props.index"
                      label="Provincia*"
                      item-text="name"
                      item-value="id"
                      :items="provinces"
                      autocomplete="off"
                      v-validate="'required'"
                      @change="loadLocalities(props.item.ProvinceSubClient.id)"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("ProvinceSubClient_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '150px' }">
                    <v-combobox
                      v-model="props.item.LocalitySubClient"
                      data-vv-as="localidad"
                      :name="'LocalitySubClient_' + props.index"
                      label="Localidad*"
                      item-text="name"
                      item-value="id"
                      :items="localities"
                      autocomplete="off"
                      v-validate="'required'"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("LocalitySubClient_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '150px' }">
                    <v-text-field :name="'NumRemito_' + props.index" v-model="props.item.NumRemito" :label="'N° de ' + $t('Remito')" data-vv-as="Numero de Remito" autocomplete="off" />
                    <span class="red--text">{{ errors.first("NumRemito_" + props.index) }}</span>
                  </td>

                  <td :style="{ minWidth: '100px' }">
                    <v-text-field :name="'DeclaredValue_' + props.index" type="number" min="0" v-model="props.item.DeclaredValue" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Weight_' + props.index" type="number" min="0" v-model="props.item.Weight" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'CubicMeters_' + props.index" type="number" min="0" v-model="props.item.CubicMeters" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Pallet_' + props.index" type="number" min="0" v-model="props.item.Pallet" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Package_' + props.index" type="number" min="0" v-model="props.item.Package" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Rack_' + props.index" type="number" min="0" v-model="props.item.Rack" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Bobina_' + props.index" type="number" min="0" v-model="props.item.Bobina" />
                  </td>

                  <td :style="{ minWidth: '90px' }">
                    <v-text-field :name="'Tambores_' + props.index" type="number" min="0" v-model="props.item.Tambores" />
                  </td>

                  <td :style="{ minWidth: '200px' }">
                    <v-text-field :name="'Observation_' + props.index" type="string" :label="$t('Observaciones')" v-model="props.item.Observation" />
                  </td>

                  <td class="text-center">
                    <v-tooltip bottom v-if="props.item.status == null">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="red" @click="removeDetail(props.item)"> indeterminate_check_box </v-icon>
                      </template>
                      <span>Remover</span>
                    </v-tooltip>
                    <v-label :style="{ color: getStatusColor(props.item.status) }" v-else>{{ props.item.status }}</v-label>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions style="position: absolute; bottom: 0; right: 0; left: 0; background: #fafafa;">
            <div class="row">
              <div class="col-md-12 pb-0">
                <v-progress-linear v-if="progress != null" :value="progress" color="green" height="20" style="height: 10px !important;"></v-progress-linear>
              </div>
              <div class="col-md-12 text-end pt-0">
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeModal">{{ $t("Cerrar") }}</v-btn>
                <v-btn color="success" text type="submit">{{ $t("Crear") }}</v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { countryService } from "@/libs/ws/countryService";
import { remitosService } from "@/libs/ws/remitosService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      search: " ",
      remitos: [],
      provinces: [],
      localities: [],
      countries: [],
      progress: null,
      headers: [
        { text: this.$t("Nombre"), value: "NameSubClient" },
        { text: this.$t("Direccion"), value: "AddressSubClient" },
        { text: "Pais", value: "CountrySubClient" },
        { text: this.$t("Provincia"), value: "ProvinceSubClient" },
        { text: this.$t("Localidad"), value: "LocalitySubClient" },
        { text: "N°" + this.$t("Remito"), value: "NumRemito" },
        { text: this.$t("Valor_declarado"), value: "DeclaredValue" },
        { text: "Altura", value: "Weight" },
        { text: "M3", value: "CubicMeters" },
        { text: this.$t("Pallets"), value: "Pallet" },
        { text: this.$t("Bultos"), value: "Package" },
        { text: this.$t("Rack"), value: "Rack" },
        { text: this.$t("Bobina"), value: "Bobina" },
        { text: this.$t("Tambores"), value: "Tambores" },
        { text: this.$t("Observaciones"), value: "Observation" },
        { text: this.$t("Accion"), value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      if (this.dialog) {
        this.addRow();
      }
    },
  },
  mounted() {
    this.loadCountries();
  },

  methods: {
    closeModal() {
      this.$emit("updateDialog", false);
      this.$emit("loadList");
      this.remitos = [];
      this.progress = null;
    },
    removeDetail(item) {
      const index_item = this.remitos.indexOf(item);
      if (index_item > -1) this.remitos.splice(index_item, 1);
    },
    addRow() {
      this.remitos.push({
        NameSubClient: "",
        CUITSubClient: "",
        AddressSubClient: "",
        CPSubClient: "",
        LocalitySubClient: "",
        ProvinceSubClient: "",
        CountrySubClient: this.countries.length > 0 ? this.countries[0] : "",
        NumRemito: "",
        DeclaredValue: 0,
        Weight: 0,
        CubicMeters: 0,
        Estate: "",
        Pallet: 0,
        Package: 0,
        Observation: "",
        Rack: 0,
        Bobina: 0,
        Tambores: 0,
        status: null,
      });
      if (this.countries.length > 0) {
        this.loadProvincies(this.countries[0].id); // Cargar provincias del primer país
      }
    },
    loadCountries() {
      countryService
        .getAll()
        .then((response) => {
          this.countries = response.countries;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadProvincies(country) {
      countryService
        .getAllProvince(country)
        .then((response) => {
          this.provinces = response.province;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadLocalities(province) {
      countryService
        .getAllLocality(province)
        .then((response) => {
          this.localities = response.locality;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    getStatusColor(status) {
      return status === "OK" ? "green" : status === "Error" ? "red" : "black";
    },
    async createItem() {
      const valid = await this.$validator.validate();
      if (valid) {
        let allSuccess = true;
        const totalRemitos = this.remitos.length;
        let processedRemitos = 0;
        this.progress = 0;

        for (const remito of this.remitos) {
          remito.status = "Guardando...";

          const item = {
            NameSubClient: remito.NameSubClient,
            CUITSubClient: remito.CUITSubClient,
            AddressSubClient: remito.AddressSubClient,
            CPSubClient: remito.CPSubClient,
            LocalitySubClient: remito.LocalitySubClient.name,
            ProvinceSubClient: remito.ProvinceSubClient.name,
            CountrySubClient: remito.ProvinceSubClient.name,
            NumRemito: remito.NumRemito,
            DeclaredValue: parseInt(remito.DeclaredValue),
            Weight: parseInt(remito.Weight),
            CubicMeters: parseFloat(remito.CubicMeters),
            Estate: remito.Estate,
            Pallet: parseInt(remito.Pallet),
            Package: parseInt(remito.Package),
            Observation: remito.Observation,
            Rack: parseInt(remito.Rack),
            Bobina: parseInt(remito.Bobina),
            Tambores: parseInt(remito.Tambores),
          };

          try {
            const response = await remitosService.createRemitosMultiples(item);

            if (response.id > 0) {
              remito.status = "OK";
              this.$toast.success(this.$t("se_creo_ok"), "Ok");
            } else {
              remito.status = "Error";
              this.$toast.error(response.error, "Error");
              allSuccess = false;
            }
          } catch (error) {
            remito.status = "Error";
            this.$toast.error(error.error || "Error en la solicitud", "Error");
            allSuccess = false;
          }

          processedRemitos++;
          this.progress = (processedRemitos / totalRemitos) * 100;
        }

        if (allSuccess) {
          this.closeModal();
        }
      }
    },
  },
};
</script>
<style>
.fixed-width-table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  max-width: 100%;
}

.fixed-width-table table {
  min-width: 1500px;
}

.fixed-width-table th,
.fixed-width-table td {
  padding: 8px;
  text-align: left;
}
</style>
